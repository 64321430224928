import React, { FC } from 'react';

import { Placeholder, mapImage } from '@sitecore/common';
import { WeDoenHetNuTextImageRendering } from '@sitecore/types/manual/road2zero/WeDoenHetNuTextImage';
import { Bleed, Grid, Image } from '@sparky';

const WeDoenHetNuTextImage: FC<WeDoenHetNuTextImageRendering> = ({ params, fields }) => {
  const isReversed = params?.isReversed === '1';

  const imageProps = mapImage(fields?.image);

  const gridColumns = {
    left: { initial: '1 / -1', lg: isReversed ? '7 / 13' : '1 / 7', xl: isReversed ? '8 / 13' : '1 / 6' },
    right: { initial: '1 / -1', lg: isReversed ? '1 / 8' : '6 / 13', xl: isReversed ? '1 / 9' : '5 / 13' },
  };

  return (
    <Grid columns="12" gridTemplateRows="1fr" alignY="center" gap={{ initial: '0', lg: '4', xl: '6' }}>
      <Grid.Item gridColumn={gridColumns.left} gridRow={{ lg: '1 / -1' }}>
        <Placeholder name="jss-wedoenhetnu-text-image-main" />
      </Grid.Item>
      <Grid.Item
        gridColumn={gridColumns.right}
        order={{ initial: -1, lg: isReversed ? -1 : 1 }}
        gridRow={{ lg: '1 / -1' }}>
        <Bleed horizontal={{ initial: 'gridGutter', lg: '0' }} bottom="3">
          {imageProps.src ? (
            <Image
              src={imageProps.src}
              alt={imageProps.alt}
              width="100%"
              height="100%"
              objectFit="cover"
              aspectRatio="4/3"
              sources={imageProps.sources}
            />
          ) : null}
        </Bleed>
      </Grid.Item>
    </Grid>
  );
};

export default WeDoenHetNuTextImage;
